import { Provider } from 'react-redux';
import { useStore } from '../config/store';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';

const SENTRY = process.env.SENTRY;

Sentry.init({
  dsn: SENTRY,
});

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import "../styles/index.scss";
import moment from 'moment';
moment.locale('id');
export default function App({ Component, pageProps }) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);

  return (
    <Provider store={store}>
      <Component {...pageProps} router={router} />

      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        autoClose={3000}
        closeOnClick={false}
      />
    </Provider>
  );
}
