import { combineReducers } from 'redux';
import * as types from 'constant/types';

// COUNTER REDUCER
const counterReducer = (state = 0, { type }) => {
  switch (type) {
    case types.INCREMENT:
      return state + 1;
    case types.DECREMENT:
      return state - 1;
    case types.RESET:
      return 0;
    default:
      return state;
  }
};

// INITIAL TIMER STATE
const initialTimerState = {
  lastUpdate: 0,
  light: false,
};

// TIMER REDUCER
const timerReducer = (state = initialTimerState, { type, payload }) => {
  switch (type) {
    case types.TICK:
      return {
        lastUpdate: payload.ts,
        light: !!payload.light,
      };
    default:
      return state;
  }
};

const initialPJP = {
  data: {},
  pin: '',
};

const pjpReducers = (state = initialPJP, action) => {
  switch (action.type) {
    case types.SET_PIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        pin: action.pin,
      };
    }
    case types.UPDATE_OWNER_SIGNATURE: {
      return {
        ...state,
        data: {
          ...state.data,
          owner_signature: action.data?.owner_signature,
        },
      };
    }
    case types.SET_SLUG: {
      return {
        ...state,
        slug: action.data,
      };
    }
    default:
      return state;
  }
};

// COMBINED REDUCERS
const reducers = {
  counter: counterReducer,
  timer: timerReducer,
  pjp: pjpReducers,
};

export default combineReducers(reducers);
